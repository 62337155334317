import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    ScrollView,
    ImageBackground,
    Text,
    Image,
    Modal,
    TouchableOpacity,
    Platform
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
import WalletBalanceList from '../../components/wallet/walletBalanceList';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import Checkbox from 'expo-checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../redux/auth/authActions';
import BalanceChart from '../../components/totalBalanceChart';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import OptionBar from '../../components/modal/OptionBar';
import LoginModal from '../../components/modal/LoginModal';
import { useIsFocused, useRoute } from '@react-navigation/native';
import Button from '../../components/Button/Button';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import CommingSoon from '../../components/modal/CommingSoon';
import { Audio } from 'expo-av';


const option = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="svg-main-icon">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect x="0" y="0" width="24" height="24"/>
    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#FE9063" opacity="0.3"/>
    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#FE9063"/>
    <rect fill="#FE9063" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
    <rect fill="#FE9063" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
    <rect fill="#FE9063" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
    <rect fill="#FE9063" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
    <rect fill="#FE9063" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
    <rect fill="#FE9063" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
</g>
</svg>`;


const ActionData = [
    {
        icon: option,
        title: "Option Bar",
        sheet: 'option',
    }
]



const Home = (props: any) => {

    const { colors } = useTheme();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const theme = useTheme();
    const isFocused = useIsFocused();
    const [toggleCheckBox, setToggleCheckBox] = useState(false);
    const [activeSheet, setActiveSheet] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMaintain, setModalMaintain] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');

    const [claimed, setClaimed] = useState({
        success: false,
        totalX: 0,
        claimedX: 0,
        withdraw_fund: 0,
        ecology: 0,
        feeY: 0,
        feeX: 0,
        message: ""
    });

    const [needY, setNeedY] = useState("");

    const [walletList, setWalletList] = useState([
        {
            id: '9',
            coin: IMAGES.dash,
            coinName: 'Dash',
            amount: '$0.0000',
            trade: '+0.0%',
            tag: "DASH",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000335'
        },
    ]);

    const dispatch = useDispatch();
    const [myBalances, setMyBalances] = useState({
        id: 1,
        user: 1,
        usdt_balance: "0.0000",
        energy_balance: "0.0000",
        btc_balance: "0.0000",
        eth_balance: "0.0000",
        solana_balance: "0.0000",
        usdt_processing: "0.0000",
        energy_processing: "0.0000",
        btc_processing: "0.0000",
        eth_processing: "0.0000",
        solana_processing: "0.0000",
        created_at: null,
        updated_at: null,
        updated_by: null
    });

    const [myXYBalance, setMyXYBalance] = useState({
        pool_x_value: "0.0000",
        released_x_value: "0.0000",
        claim_x_value: "0.0000",
        y_balance: "0.0000",
    });

    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 0,
        mobile: null,
        profileImage: "",
        parent: null,
        "ref": "",
        "status": 0,
        "type": "0",
        "verification": ""
    });

    const [sound, setSound] = useState({});
    const [sound2, setSound2] = useState({});

    async function playSound() {
        const { sound } = await Audio.Sound.createAsync(
            require('../../assets/mp3/coins.mp3') // Replace with your sound file path
        );
        setSound(sound);
        await sound.playAsync();
    }

    async function playSound2() {
        const { sound } = await Audio.Sound.createAsync(
            require('../../assets/mp3/glass.mp3') // Replace with your sound file path
        );
        setSound2(sound);
        await sound.playAsync();
    }


    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        // setTimeout(() => {
        //     setModalVisible(false);
        // }, 5000);
    }

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res1: any) => {
            // console.log('res', res);
            setMyBalances(res1);


            PrivateApi.get('/xy-buy/my', tokens.user).then((res2: any) => {
                setMyXYBalance(res2);
                // console.log('/////////////////////////////')
                // console.log(res2);
                setWalletBalance(res1, res2);
                // console.log('//////////////////////////////')
            }).catch((err) => {
                console.log('err', err);
            });


        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getNeedYBalance = () => {
        console.log('---------------------------------------------------------------------- 00000 ');
        PrivateApi.get('/xy-mining/needY', tokens.user).then((res: any) => {
            console.log('---------------------------------------------------------------------- **** ', res);
            console.log(res);
            setNeedY(res.y);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getXYBalancesMy = () => {
        PrivateApi.get('/xy-buy/my', tokens.user).then((res: any) => {
            setMyXYBalance(res);
            // console.log('/////////////////////////////')
            // console.log(res);
            // console.log('//////////////////////////////')
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const setWalletBalance = async (res1: any, res2: any) => {
        // console.log("))))))))))))))))))))))))))))))))))))))))))))))))))))))")
        // console.log(res1);

        let walletListNew: any = [];

        let objUsdt = {
            id: '1',
            coin: IMAGES.usdt,
            coinName: 'Tether',
            amount: res1.usdt_balance,
            trade: '+0.00%',
            tag: "USDT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objBtc = {
            id: '2',
            coin: IMAGES.bitcoin,
            coinName: 'Bitcoin',
            amount: res1.btc_balance,
            trade: '+0.00%',
            tag: "BTC",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objEth = {
            id: '3',
            coin: IMAGES.ethereum,
            coinName: 'Ethereum',
            amount: res1.eth_balance,
            trade: '+0.00%',
            tag: "ETH",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objSol = {
            id: '4',
            coin: IMAGES.solana,
            coinName: 'Solana',
            amount: res1.solana_balance,
            trade: '+0.00%',
            tag: "SOL",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objBitc = {
            id: '5',
            coin: IMAGES.lun,
            coinName: 'BiTC Token',
            amount: res2.claim_x_value,
            trade: '+0.00%',
            tag: "BiTC",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objFat = {
            id: '6',
            coin: IMAGES.dash,
            coinName: 'FLT Token',
            amount: res2.y_balance,
            trade: '+0.00%',
            tag: "FAT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objOwnI = {
            id: '7',
            coin: IMAGES.gdb,
            coinName: 'NFT Token',
            amount: '0.0000',
            trade: '+0.00%',
            tag: "NFTT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objOwnII = {
            id: '8',
            coin: IMAGES.etp,
            coinName: 'Native Token',
            amount: '0.0000',
            trade: '+0.00%',
            tag: "NTVT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let dummy = {
            id: '99999',
            coin: IMAGES.nem,
            coinName: 'Tether',
            amount: "0",
            trade: '+0.00%',
            tag: "USDT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }

        await walletListNew.push(objUsdt, objBtc, objEth, objSol, objBitc, objFat);

        setWalletList(walletListNew);

    }

    const xClaim = () => {

        PrivateApi.get('/xy-mining/xClaim', tokens.user).then((res: any) => {
            setClaimed(res);
            if (res.success) {
                playSound();
                model('information-circle-sharp', 'Success', 'Successfully claim BiTC Token');
            } else {
                playSound2();
                model('information-circle-sharp', 'Error', 'Failed to claim BiTC Token');
            }

            getXYBalancesMy();
            getMyBalance();
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            // console.log('res', res);
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const checkIsVefifiedComplete = () => {
        PrivateApi.post('/user/checkIsVefifiedComplete', tokens.user).then((res: any) => {
            console.log('checkIsVefifiedComplete **********  -> ', res);

        }).catch((err) => {
            console.log('err', err);
        });
    }

    React.useEffect(() => {
        getXYBalancesMy();
        getMyBalance();
        getProfile();
        getNeedYBalance();
        checkIsVefifiedComplete();
        // setWalletBalance(myBalances);
    }, [
        isFocused
    ]);

    return (
        <View style={{ ...styles.container, backgroundColor: colors.background }}>
            <ScrollView>

                <BalanceChart />
                <Animatable.View
                    animation="fadeInRight"
                    duration={1000}
                    delay={500}
                >

                    <View style={{
                        padding: 10,
                        // height: 150,
                        // marginTop: -30,
                        // backgroundColor: colors.primary,
                    }}>
                        <ImageBackground
                            source={IMAGES.gif2}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                paddingVertical: 18,
                                // borderRadius: SIZES.radius_lg,
                                borderTopLeftRadius: SIZES.radius_lg,
                                borderTopRightRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                                height: 150,
                            }}
                        >
                        </ImageBackground>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: 20,
                            backgroundColor: colors.card,
                            height: 80,
                            borderBottomLeftRadius: SIZES.radius_lg,
                            borderBottomRightRadius: SIZES.radius_lg,
                            ...GlobalStyleSheet.shadow,
                        }}>
                            <View style={{ alignItems: 'center', }}>
                                <Text style={{ ...FONTS.fontXs, color: colors.text, textAlign: 'center' }}>Daily Release (BiTC)</Text>
                                <Text style={{ ...FONTS.h6, color: COLORS.white, textAlign: 'center' }}>{Number(myXYBalance.released_x_value).toFixed(4)}</Text>
                            </View>
                            <View style={{ alignItems: 'center', marginTop: -20, }}>
                                <TouchableOpacity
                                    // onPress={() => navigation.navigate('Trade')}
                                    onPress={() => {
                                        // setActiveSheet(ActionData.sheet); 
                                        xClaim();
                                    }}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 8,
                                        borderRadius: SIZES.radius,
                                        // alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: COLORS.primaryLight,
                                            height: 60,
                                            width: 60,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 16,
                                            // marginBottom: 6,
                                        }}
                                    >
                                        <Image
                                            source={ICONS.fingerprint2}
                                            style={{
                                                height: 50,
                                                width: 50,
                                                // tintColor: COLORS.primary,
                                            }}
                                        />
                                    </View>
                                    {/* <Text style={{ ...FONTS.font, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>P2P</Text> */}
                                </TouchableOpacity>
                            </View>
                            <View style={{ alignItems: 'center', }}>
                                <Text style={{ ...FONTS.fontXs, color: colors.text, textAlign: 'center' }}>Drive Value (FLT)</Text>
                                <Text style={{ ...FONTS.h6, color: COLORS.white, textAlign: 'center' }}>{Number(needY).toFixed(4)}</Text>
                            </View>
                        </View>
                    </View>



                </Animatable.View>


                <View
                    style={{
                        marginBottom: 15,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 5,
                    }}
                >
                    <TouchableOpacity
                        onPress={() => props.navigation.navigate('p2p')}
                        style={{
                            paddingHorizontal: 15,
                            paddingVertical: 8,
                            borderRadius: SIZES.radius,
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                // backgroundColor: 'rgba(255,255,255,.1)',
                                backgroundColor: COLORS.primaryLight,
                                height: 60,
                                width: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 16,
                                marginBottom: 6,
                            }}
                        >
                            <Image
                                source={ICONS.cashwallet}
                                style={{
                                    height: 30,
                                    width: 30,
                                    tintColor: COLORS.primary,
                                }}
                            />
                        </View>
                        <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>
                            P2P Trading
                        </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => props.navigation.navigate('Resonance')}
                        style={{
                            paddingHorizontal: 15,
                            paddingVertical: 8,
                            borderRadius: SIZES.radius,
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: COLORS.primaryLight,
                                height: 60,
                                width: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 16,
                                marginBottom: 6,
                            }}
                        >
                            <Image
                                source={ICONS.bank}
                                style={{
                                    height: 30,
                                    width: 30,
                                    tintColor: COLORS.primary,
                                }}
                            />
                        </View>
                        <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>Resonance</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => props.navigation.navigate('Rewards')}
                        style={{
                            paddingHorizontal: 15,
                            paddingVertical: 8,
                            borderRadius: SIZES.radius,
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: COLORS.primaryLight,
                                height: 60,
                                width: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 16,
                                marginBottom: 6,
                            }}
                        >
                            <Image
                                source={ICONS.badge}
                                style={{
                                    height: 30,
                                    width: 30,
                                    tintColor: COLORS.primary,
                                }}
                            />
                        </View>
                        <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>Rewards</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => props.navigation.navigate('Swap')}
                        style={{
                            paddingHorizontal: 15,
                            paddingVertical: 8,
                            borderRadius: SIZES.radius,
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: COLORS.primaryLight,
                                height: 60,
                                width: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 16,
                                marginBottom: 6,
                            }}
                        >
                            <Image
                                source={ICONS.transfer}
                                style={{
                                    height: 30,
                                    width: 30,
                                    tintColor: COLORS.primary,
                                }}
                            />
                        </View>
                        <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>Transfer</Text>
                    </TouchableOpacity>

                </View>


                {/* <Animatable.View
                    animation="fadeInLeft"
                    duration={1000}
                    delay={500}
                >
                    <View style={{ ...GlobalStyleSheet.row, paddingHorizontal: 10, marginBottom: 15, marginTop: 5 }}>

                        <View style={{ ...GlobalStyleSheet.col50 }}>
                            <TouchableOpacity
                                onPress={() => setModalMaintain(true)}
                                style={{
                                    borderRadius: SIZES.radius,
                                    padding: 20,
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <View
                                    style={{
                                        height: 40,
                                        width: 40,
                                        borderRadius: SIZES.radius,
                                        backgroundColor: COLORS.primaryLight,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginBottom: 10
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            resizeMode: 'contain',
                                            tintColor: COLORS.primary,
                                        }}
                                        source={ICONS.cashwallet}
                                    />
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.title }}>P2P Trading</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={{ ...GlobalStyleSheet.col50 }}>
                            <TouchableOpacity
                                onPress={() => props.navigation.navigate('Resonance')}
                                style={{
                                    borderRadius: SIZES.radius,
                                    padding: 20,
                                    position: 'relative',
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                                    <View
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: SIZES.radius,
                                            backgroundColor: COLORS.primaryLight,
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Image
                                            style={{
                                                height: 22,
                                                width: 22,
                                                resizeMode: 'contain',
                                                tintColor: COLORS.primary,
                                            }}
                                            source={ICONS.bank}
                                        />
                                    </View>
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.title }}>Resonance</Text>
                            </TouchableOpacity>
                        </View>

                    </View>
                </Animatable.View>

                <Animatable.View
                    animation="fadeInRight"
                    duration={1000}
                    delay={500}
                >
                    <View style={{ ...GlobalStyleSheet.row, paddingHorizontal: 10, marginBottom: 15 }}>
                        <View style={{ ...GlobalStyleSheet.col50 }}>
                            <TouchableOpacity
                                onPress={() => setModalMaintain(true)}
                                style={{
                                    borderRadius: SIZES.radius,
                                    padding: 20,
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <View
                                    style={{
                                        height: 40,
                                        width: 40,
                                        borderRadius: SIZES.radius,
                                        backgroundColor: COLORS.primaryLight,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginBottom: 10
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            resizeMode: 'contain',
                                            tintColor: COLORS.primary,
                                        }}
                                        source={ICONS.badge}
                                    />
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.title }}>Rewards</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ ...GlobalStyleSheet.col50 }}>
                            <TouchableOpacity
                                onPress={() => props.navigation.navigate('Swap')}
                                style={{
                                    borderRadius: SIZES.radius,
                                    padding: 20,
                                    position: 'relative',
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                                    <View
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: SIZES.radius,
                                            backgroundColor: COLORS.primaryLight,
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Image
                                            style={{
                                                height: 22,
                                                width: 22,
                                                resizeMode: 'contain',
                                                tintColor: COLORS.primary,
                                            }}
                                            source={ICONS.transfer}
                                        />
                                    </View>
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.title }}>Transfer</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Animatable.View> */}



                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingHorizontal: 15,
                        marginBottom: 12,
                    }}
                >
                    <Text style={{ ...FONTS.h6, color: colors.text }}>Wallet Balance</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 8 }}>
                        <View
                            style={[Platform.OS === 'ios' && {
                                transform: [{
                                    scale: .8
                                }],
                                marginRight: 5,
                            }]}
                        >
                            {/* <Checkbox
                                // tintColors={{ true: COLORS.primary, false: colors.text }}
                                // onCheckColor={COLORS.primary}
                                // onTintColor={COLORS.primary}
                                value={toggleCheckBox}
                                onValueChange={(newValue) => setToggleCheckBox(newValue)}
                            /> */}
                        </View>
                        {/* <Text style={{ ...FONTS.font, color: colors.text, marginLeft: 5 }}>Hide Balances</Text> */}
                    </View>
                </View>





                <WalletBalanceList
                    navigate={props.navigation.navigate}
                    destination="Swap"
                    data={walletList}
                    theme={theme}
                />



            </ScrollView>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    {/* <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} /> */}
                    {/* <LoginModal/> */}

                    <View
                        style={{
                            backgroundColor: colors.card,
                            maxWidth: 330,
                            width: '100%',
                            borderRadius: SIZES.radius,
                            paddingHorizontal: 10,
                            paddingVertical: 10,
                        }}
                    >
                        <View style={{
                            paddingHorizontal: 15,
                            borderBottomWidth: 1,
                            borderColor: colors.borderColor,
                            paddingVertical: 0,
                            marginBottom: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <Text style={{ ...FONTS.h5, color: colors.title }}>Claim</Text>
                            <TouchableOpacity style={{ padding: 10 }} onPress={() => setModalVisible(false)}>
                                <FeatherIcon name={'x'} size={24} color={colors.title} />
                            </TouchableOpacity>
                        </View>
                        <View style={GlobalStyleSheet.container}>

                            <View style={{
                                marginTop: -20,
                                marginBottom: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                    <FeatherIcon name={'user-check'} size={15} color={COLORS.info} />
                                    <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{profile.email}</Text>
                                </View>
                                {/* <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                    <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                    <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{myXYBalance.released_x_value}</Text>
                                </View> */}
                            </View>

                            {claimed.success ?
                                <>
                                    <View style={{
                                        marginTop: 2,
                                        marginBottom: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            {/* <FeatherIcon name={'user-check'} size={15} color={COLORS.info} /> */}
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>Total BiTC</Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{Number(claimed.totalX).toFixed(2)}</Text>
                                        </View>
                                    </View>

                                    <View style={{
                                        marginTop: 2,
                                        marginBottom: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            {/* <FeatherIcon name={'user-check'} size={15} color={COLORS.info} /> */}
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>Claimed</Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{Number(claimed.claimedX).toFixed(2)}</Text>
                                        </View>
                                    </View>


                                    <View style={{
                                        marginTop: 2,
                                        marginBottom: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            {/* <FeatherIcon name={'user-check'} size={15} color={COLORS.info} /> */}
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>Withdraw Fund</Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{Number(claimed.withdraw_fund).toFixed(2)}</Text>
                                        </View>
                                    </View>

                                    <View style={{
                                        marginTop: 2,
                                        marginBottom: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            {/* <FeatherIcon name={'user-check'} size={15} color={COLORS.info} /> */}
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>Ecology</Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{Number(claimed.ecology).toFixed(2)}</Text>
                                        </View>
                                    </View>

                                    <View style={{
                                        marginTop: 2,
                                        marginBottom: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            {/* <FeatherIcon name={'user-check'} size={15} color={COLORS.info} /> */}
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>FLT Fee</Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{Number(claimed.feeY).toFixed(2)}</Text>
                                        </View>
                                    </View>

                                    <View style={{
                                        marginTop: 2,
                                        marginBottom: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            {/* <FeatherIcon name={'user-check'} size={15} color={COLORS.info} /> */}
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>BiTC Fee</Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                            <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{Number(claimed.feeX).toFixed(2)}</Text>
                                        </View>
                                    </View>



                                    <View style={{ alignItems: 'center', marginBottom: 15 }}>
                                        <Ionicons name='checkmark-circle' style={{ marginBottom: 8 }} color={COLORS.info} size={60} />
                                        <Text style={{ ...FONTS.h5, fontWeight: '900', color: COLORS.info, marginBottom: 6 }}>Claim Success</Text>
                                    </View>
                                </> :

                                <View style={{ alignItems: 'center', marginBottom: 15 }}>
                                    <Ionicons name='close-circle-outline' style={{ marginBottom: 8 }} color={COLORS.warning} size={60} />
                                    <Text style={{ ...FONTS.h5, fontWeight: '900', color: COLORS.warning, marginBottom: 6 }}>Claim Unsuccess</Text>
                                </View>
                            }



                            <Button title={'Okey'} onPress={() => setModalVisible(false)} />


                        </View>
                    </View>



                </View>
            </Modal>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalMaintain}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalMaintain(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <CommingSoon />
                </View>
            </Modal>

        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})

export default Home;
