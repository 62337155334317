import React, { useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    PermissionsAndroid,
    Platform,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import { useIsFocused, useRoute } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { launchImageLibrary } from 'react-native-image-picker';
import * as ImagePicker from 'expo-image-picker';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import * as Clipboard from 'expo-clipboard';
import { logOut } from '../../redux/auth/authActions';

const Profile = (props: any) => {

    const { colors } = useTheme();
    const dispatch = useDispatch();
    const [imgUrl, setImgUrl] = useState(null);
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        id: 0,
        mobile: null,
        profileImage: "",
        parent: null,
        nic_number: null,
        "ref": "Mx9vXy5EcP",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb",
        verifided: 0,
    });

    const [myBalances, setMyBalances] = useState({
        id: 1,
        user: 1,
        usdt_balance: "0.0000",
        energy_balance: "0.0000",
        btc_balance: "0.0000",
        eth_balance: "0.0000",
        solana_balance: "0.0000",
        usdt_processing: "0.0000",
        energy_processing: "0.0000",
        btc_processing: "0.0000",
        eth_processing: "0.0000",
        solana_processing: "0.0000",
        created_at: null,
        updated_at: null,
        updated_by: null,

    });

    const [myXyBuy, setMyXyBuy] = useState({
        "id": 0,
        "userId": 0,
        "parent_userId": 0,
        "parent_xyBuyId": 0,
        "direct_team_count": 0,
        "total_team_investment": "",
        "rank": 0,
        "activated_at": "",
        "plan_id": 0,
        "invested_x_value": "",
        "pool_x_value": "",
        "released_x_value": "",
        "claim_x_value": "",
        "y_balance": "",
        "ecology": "",
        "withdraw_fund": "",
        "status": 0,
        "ranking": 0,
        "expired_at": 0
    });

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res1: any) => {
            setMyBalances(res1);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getMyXyBuy = () => {
        PrivateApi.get('/xy-buy/my', tokens.user).then((res1: any) => {
            console.log('XY ---- Buy -------> ', res1);
            setMyXyBuy(res1);
        }).catch((err) => {
            console.log('err', err);
        });
    }



    React.useEffect(() => {
        getProfile();
        getMyBalance();
        getMyXyBuy();

    }, [isFocused]);

    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            if (res.profileImage) {
                getProfileImageFromAPIGetMethod("https://upload.bitzwallet.com/images/" + res.profileImage);
            }
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const copyToClipboard = async () => {
        console.log(profile.ref)
        Clipboard.setStringAsync(profile.ref);
    };

    const handleProfileImage = async () => {

        let result: any = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true,
            quality: 1,
        });
        // console.log(result);
        if (!result.canceled) {
            setImgUrl(result.assets[0].uri);
            // alert(result);
            uploadImage(result.assets[0].uri);
        } else {
            alert('You did not select any image.');
        }
    }


    const uploadImage = async (uri: any) => {
        try {
            console.log('URL');
            console.log(uri);

            const res = await fetch(Platform.OS === 'ios' ? uri.replace('file://', '') : uri);
            const blob = await res.blob();
            console.log('res');
            console.log(res);

            const formData = new FormData();
            formData.append('file', blob, 'photo.jpg');

            console.log('______FORM DATA______');

            console.log(formData);

            let response = await fetch('https://upload.bitzwallet.com/upload', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                // Parse the response JSON
                const data = await response.json();
                console.log("------------------------------------------------------>")
                console.log(data);
                const filename = data.filename;
                console.log("File uploaded successfully with filename: " + filename);
                console.log("<------------------------------------------------------")
                setProfileImage(filename);
            } else {
                console.log("Error uploading file. Status: " + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setProfileImage = async (uri: any) => {

        PrivateApi.post('/user/addProfilePic', tokens.user, { profileImage: uri }).then((res: any) => {
            console.log('res', res);
            getProfile();
        }
        ).catch((err) => {
            console.log('err', err);
        });

    }

    const getProfileImageFromAPIGetMethod = async (uri: any) => {
        try {
            let response = await fetch(uri);
            console.log("-------------->")
            console.log(response);
            // set response image to selectedImage
            if (response.status == 200) {
                setImgUrl(uri);
            }

            console.log("<--------------")
        } catch (error) {
            console.error(error);
        }
    }

    const navLinks = [
        {
            icon: ICONS.verified,
            title: "Verification",
            navigate: "VerificationMain",
        },
        {
            icon: ICONS.doubts,
            title: "Know Your Crypto",
            navigate: "Trc20Add",
        },
        {
            icon: ICONS.setting,
            title: "Settings",
            navigate: "settings",
        },
        {
            icon: ICONS.history,
            title: "Performance",
            navigate: "Performance",
        },
        {
            icon: ICONS.badge,
            title: "Rewards",
            navigate: "Rewards",
        },
        {
            icon: ICONS.wallet,
            title: "Payment",
            navigate: "paymentMethod",
        },
        {
            icon: ICONS.support,
            title: "Helpdesk",
            navigate: "Helpdesk",
        },
        // {
        //     icon: ICONS.logout,
        //     title: "Logout",
        //     navigate: "Login",
        // },
    ]


    return (
        <View
            style={{
                flex: 1,
                backgroundColor: colors.background,
            }}
        >
            <HeaderBar
                leftIcon={'back'}
                title={"Profile"}
            />
            <ScrollView
                contentContainerStyle={{
                    paddingBottom: 100,
                }}
            >
                <View
                    style={{
                        padding: 15,
                    }}
                >


                    <ImageBackground
                        source={IMAGES.bg1}
                        style={{
                            flexDirection: 'row',
                            paddingHorizontal: 18,
                            paddingVertical: 18,
                            borderRadius: SIZES.radius_lg,
                            overflow: 'hidden',
                            alignItems: 'center',
                        }}
                    >
                        <View style={{ marginRight: 18, borderWidth: 3, borderRadius: 80, borderColor: 'rgba(255,255,255,.1)' }}>
                            <Image
                                source={imgUrl ? { uri: imgUrl } : IMAGES.pic1}
                                style={{
                                    height: 120,
                                    width: 120,
                                    borderRadius: 120,
                                }}
                            />
                            <TouchableOpacity
                                onPress={() => handleProfileImage()}
                                activeOpacity={.9}
                                style={{
                                    height: 28,
                                    width: 28,
                                    position: 'absolute',
                                    backgroundColor: COLORS.primary,
                                    borderRadius: 28,
                                    bottom: 0,
                                    right: 0,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <FeatherIcon size={14} color={COLORS.white} name='edit' />
                            </TouchableOpacity>
                        </View>
                        <View>
                            <Text style={{ ...FONTS.h6, color: COLORS.white, marginBottom: 7 }}>{profile.userName}</Text>
                            <Text style={{ color: COLORS.white, marginBottom: 4 }}>UID : BA-{100100 + Number(profile.id)}</Text>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 3,
                                }}
                            >
                                <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='mail' />
                                <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>{profile.email}</Text>
                            </View>
                            {profile.mobile ?
                                // <View
                                //     style={{
                                //         flexDirection: 'row',
                                //         alignItems: 'center',
                                //         marginBottom: 3,
                                //     }}
                                // >
                                //     <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='smartphone' />
                                //     <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>{profile.mobile}</Text>
                                // </View>
                                ""
                                : ""}

                            {profile.nic_number ?
                                // <View
                                //     style={{
                                //         flexDirection: 'row',
                                //         alignItems: 'center',
                                //         marginBottom: 3,
                                //     }}
                                // >
                                //     <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='user-check' />
                                //     <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>{profile.nic_number}</Text>
                                // </View>
                                "" : ""}
                            <TouchableOpacity
                                onPress={() => copyToClipboard()}
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='copy' />
                                <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>{profile.ref}</Text>
                            </TouchableOpacity>
                            {profile.verifide === 1 ?
                                <Text style={{ fontWeight: "800", color: COLORS.primary, opacity: .6, padding: 5, borderRadius: 10, margin: 4 }} > Verified</Text>
                                :
                                <Text style={{ fontWeight: "800", color: COLORS.danger, opacity: .6, padding: 5, borderRadius: 10, margin: 4 }} > Unverified</Text>
                            }
                        </View>
                    </ImageBackground>


                    <ImageBackground
                        source={IMAGES.bg1}
                        style={{
                            marginTop: 20,
                            flexDirection: 'column',
                            paddingHorizontal: 18,
                            paddingVertical: 18,
                            borderRadius: SIZES.radius_lg,
                            overflow: 'hidden',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ fontWeight: "200", fontSize: 18, color: COLORS.white, }} > Assest </Text>
                        <Text style={{ fontWeight: "800", fontSize: 24, color: COLORS.white, }} > $ {(Number(myBalances.usdt_balance) + Number(myBalances.usdt_processing)).toFixed(4)} </Text>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 15 }}>
                            <View style={{ flexDirection: 'column', alignItems: 'center', marginRight: 50 }}>
                                <Text style={{ fontWeight: "200", fontSize: 16, color: COLORS.white, }} >Available</Text>
                                <Text style={{ fontWeight: "800", fontSize: 18, color: COLORS.white, }} > {myBalances.usdt_balance}</Text>
                            </View>
                            <View style={{ flexDirection: 'column', alignItems: 'center', marginLeft: 50 }}>
                                <Text style={{ fontWeight: "200", fontSize: 16, color: COLORS.white, }} >Frozen</Text>
                                <Text style={{ fontWeight: "800", fontSize: 18, color: COLORS.white, }} > {myBalances.usdt_processing}</Text>
                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 15 }}>
                            <View style={{ flexDirection: 'column', alignItems: 'center', marginRight: 50 }}>
                                <Text style={{ fontWeight: "200", fontSize: 16, color: COLORS.white, }} >Ecology</Text>
                                <Text style={{ fontWeight: "800", fontSize: 18, color: COLORS.white, }} > {myXyBuy.ecology}</Text>
                            </View>
                            <View style={{ flexDirection: 'column', alignItems: 'center', marginLeft: 50 }}>
                                <Text style={{ fontWeight: "200", fontSize: 16, color: COLORS.white, }} >W. Fund</Text>
                                <Text style={{ fontWeight: "800", fontSize: 18, color: COLORS.white, }} > {myXyBuy.withdraw_fund}</Text>
                            </View>
                        </View>
                    </ImageBackground>


                    <View
                        style={{
                            paddingHorizontal: 18,
                            paddingVertical: 15,
                            borderRadius: SIZES.radius_lg,
                            backgroundColor: colors.card,
                            marginTop: 15,
                            ...GlobalStyleSheet.shadow,
                        }}
                    >
                        {navLinks.map((data, index) => {
                            return (
                                <TouchableOpacity
                                    key={index}
                                    onPress={() => props.navigation.navigate(data.navigate)}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingVertical: 16,
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 20,
                                            width: 20,
                                            tintColor: colors.text,
                                            marginRight: 14,
                                        }]}
                                        source={data.icon}
                                    />
                                    <Text style={{ ...FONTS.font, flex: 1, ...FONTS.fontMedium, color: colors.title }}>{data.title}</Text>
                                    <FeatherIcon size={18} color={colors.text} name='chevron-right' />
                                </TouchableOpacity>
                            )
                        })}
                        <TouchableOpacity
                            onPress={() => dispatch(logOut())}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingVertical: 16,
                            }}
                        >
                            <Image
                                style={[{
                                    height: 20,
                                    width: 20,
                                    tintColor: colors.text,
                                    marginRight: 14,
                                }]}
                                source={ICONS.logout}
                            />
                            <Text style={{ ...FONTS.font, flex: 1, ...FONTS.fontMedium, color: colors.title }}>Logout</Text>
                            <FeatherIcon size={18} color={colors.text} name='chevron-right' />
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

export default Profile