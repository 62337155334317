import React, { useEffect, useRef, useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    PermissionsAndroid,
    Platform,
    TextInput,
    Modal,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import { useIsFocused, useRoute } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import theme, { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { launchImageLibrary } from 'react-native-image-picker';
import * as ImagePicker from 'expo-image-picker';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import * as Clipboard from 'expo-clipboard';
import { logOut } from '../../redux/auth/authActions';
import CustomButton from '../../components/customButton';
import OptionBar from '../../components/modal/OptionBar';
import RBSheet from 'react-native-raw-bottom-sheet';
import WithdrawCryptoQr from '../../components/modal/withdrawCryptoQr';
import WithdrawCryptoOTP from '../../components/modal/withdrawCryptoOTP';
import Checkbox from 'expo-checkbox';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { P2P_PERCENTAGE } from '../../config/config'

const CoinItem = [
    {
        icon: IMAGES.trc20,
        coin: 'TRC20',
        sortName: 'TRC20 Network',
    },
    // {
    //     icon: IMAGES.erc20,
    //     coin: 'ERC20',
    //     sortName: 'ERC',
    // },
    // {
    //     icon: IMAGES.solana,
    //     coin: 'SOLANA',
    //     sortName: 'SOL',
    // },

];

const P2P = (props: any) => {

    const { colors } = useTheme();
    const theme = useTheme();
    const refRBSheet = useRef();
    const dispatch = useDispatch();
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);

    const [planId, setPlanId] = useState(0);

    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        nic_number: null,
        "ref": "Mx9vXy5EcP",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb"
    });






    const [ItemValue, setItemValue] = useState(CoinItem[0]);
    const [modalVisible, setModalVisible] = useState(false);
    const [withdrawRBSheet, setWithdrawRBSheet] = useState('');
    const [WithdrawAddress, setWithdrawAdress] = useState('');
    const [WithdrawAmount, setWithdrawAmount] = useState(0);
    const [P2P_amount, setP2PAmount] = useState(0);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [freezeAmount, setFreezeAmount] = useState(0);
    const [alertModalVisible, setAlertModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [USDTValue, setUSDTValue] = useState('');

    const [toggleCheckBox, setToggleCheckBox] = useState(false);

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setAlertModalVisible(true);
        setTimeout(() => {
            setAlertModalVisible(false);
        }, 3000);
    }

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res1: any) => {
            setUSDTValue(res1.usdt_balance);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const saveWithRequest = () => {

        if (Number(P2P_amount) > 0 && Number(P2P_amount) <= Number(USDTValue)) {
            const prefix = 100100;

            let uid = Number(WithdrawAddress) - prefix;

            let data = {
                amount: P2P_amount,
                from_user_id: 0,
                to_user_id: uid,
            }

            PrivateApi.post('/xy-p2p', tokens.user, data).then((res: any) => {
                console.log(res);
                model('checkmark-circle-sharp', 'Success', 'P2P request has been sent');
            }).catch((err) => {
                console.log(err);
            });

            console.log(data);
        } else {
            model('information-circle-sharp', 'Error', 'P2P amount is not valid');
        }


    }






    const calwithdrawAmount = (txtAmunt: any) => {
        console.log(txtAmunt);
        setP2PAmount(0);

        let wa = Number(txtAmunt);
        let p2p = Number(P2P_PERCENTAGE) * wa / 100;
        setP2PAmount(p2p);

        if (Number(p2p) > 0 && Number(p2p) <= Number(USDTValue)) {

        } else {
            model('information-circle-sharp', 'Error', 'P2P amount is not valid');
        }
    }

    const [verified, setVerified] = useState(false);

    const checkIsVerifyed = () => {
        PrivateApi.post('/user/checkIsVefifiedComplete', tokens.user).then((res: any) => {
            console.log('checkIsVefifiedComplete **********  -> ', res);
            if (res == true) {
                setVerified(true);
            }
            else {
                setVerified(false);
            }
            return res;

        }).catch((err) => {
            console.log('err', err);
            return false
        });
    }

    const getMyXyBuy = () => {
        PrivateApi.get('/xy-buy/my', tokens.user).then((res: any) => {
            console.log('res', res);
            if (res) {
                setPlanId(res.plan_id);
            }
            // setXYPlans(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }


    useEffect(() => {
        getMyBalance();
        checkIsVerifyed();
        getMyXyBuy();

    }, []);


    return (

        <>

            <Modal
                animationType="slide"
                transparent={true}
                visible={alertModalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setAlertModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                height={withdrawRBSheet === 'Qrcode' ? 650 : withdrawRBSheet === 'otp' ? 240 : 240}
                openDuration={300}
                customStyles={{
                    container: {
                        backgroundColor: colors.background,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                    },
                    draggableIcon: {
                        marginTop: 5,
                        marginBottom: 0,
                        height: 5,
                        width: 90,
                        backgroundColor: colors.borderColor,
                    }
                }}
            >
                {theme.dark &&
                    <View
                        // colors={["rgba(22,23,36,.7)","rgba(22,23,36,0)"]}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                    </View>
                }
                {
                    (withdrawRBSheet === 'Qrcode') ? <WithdrawCryptoQr /> :
                        (withdrawRBSheet === 'otp') ? <WithdrawCryptoOTP /> : <WithdrawCryptoOTP />
                }

            </RBSheet>


            <View
                style={{
                    flex: 1,
                    backgroundColor: colors.background,
                }}
            >
                <HeaderBar
                    leftIcon={'back'}
                    title={"P2P"}
                />
                <ScrollView
                    contentContainerStyle={{
                        paddingTop: 20,
                        paddingBottom: 100,
                        paddingHorizontal: 20,
                    }}
                >
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',  // Align children to the right
                        alignItems: 'center',       // Center children vertically
                        marginBottom: 20,
                        marginTop: 20,
                        height: 50,
                        borderRadius: 10,

                    }}>
                        <Text style={{ color: colors.text }} >Records</Text>
                        <TouchableOpacity
                            // onPress={() => { props.navigation.navigate("WithdrawalNotifications") }}
                            style={{
                                height: '100%',
                                width: 50,  // Adjust width as needed
                                alignItems: 'center',  // Center content horizontally within TouchableOpacity
                                justifyContent: 'center',  // Center content vertically within TouchableOpacity
                            }}
                        >

                            <FeatherIcon name='list' size={22} color={colors.title} />
                        </TouchableOpacity>

                    </View>

                    <View
                        style={[{ flex: 1 }, Platform.OS === "ios" && { paddingTop: 100 }]}
                    >

                        <ScrollView >

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ ...FONTS.font, color: colors.text, marginRight: 5 }}>Available:</Text>
                                    <Text style={{ ...FONTS.font, color: COLORS.primary }}>{USDTValue} USDT</Text>
                                </View>
                            </View>




                            <View
                                style={{
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.formControl,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <TextInput
                                    style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                    placeholder='UID'
                                    placeholderTextColor={colors.text}
                                    onChangeText={(text) => setWithdrawAdress(text)}
                                    value={WithdrawAddress.toString()}
                                //value='0xbc6b1972ea764159a4cf1c0'
                                />
                            </View>



                            <View
                                style={{
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.formControl,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <TextInput
                                    style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                    keyboardType="numeric"
                                    inputMode='numeric'
                                    placeholder='Package Amount'
                                    onChangeText={(text) => calwithdrawAmount(text)}
                                    placeholderTextColor={colors.text}
                                />
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ ...FONTS.font, color: colors.text, marginRight: 5 }}>Percentage :</Text>
                                    <Text style={{ ...FONTS.font, color: COLORS.primary }}>{P2P_PERCENTAGE.toFixed(2)}%</Text>
                                </View>
                            </View>

                            <View style={{ alignItems: 'center', marginBottom: 30, marginTop: 10 }}>
                                <Text style={{ ...FONTS.h2, color: colors.title }}>{P2P_amount.toFixed(2)} USDT</Text>
                                <Text style={{ ...FONTS.font, color: colors.text }}>Receive amount</Text>
                                {/* {freezeAmount > 0 && <Text style={{ ...FONTS.h4, color: "red" }}>Freeze amount : {freezeAmount.toFixed(2)}</Text>} */}

                            </View>

                            <View style={{ alignItems: 'center', marginBottom: 30, marginTop: 10 }}>

                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    1.to ensure the safety of your funds,your p2p trading request will be manually reviewed if your security strategy or password is changed please wait for phone calls or email.
                                </Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    2.est.arrival within 24hr to your requested UID ,unless not received within 24hr please contact customer support team.
                                </Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    3.In the event of any initial stage token exchange by any person or company, that person or company shall assume full responsibility.
                                </Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    4.it should be done with a basic understanding between the person being bought and the person being sold
                                </Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    5.rules
                                    (external 60%-internal 40% on DEC,external 70%-internal 30% on JAN,external 80%-internal 20% on FEB.t&c apply)
                                </Text>

                            </View>

                            <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 10 }}>
                                <View
                                    style={[Platform.OS === 'ios' && {
                                        transform: [{
                                            scale: .8
                                        }],
                                        marginRight: 5,
                                    }]}
                                >
                                    <Checkbox
                                        // tintColors={{ true: COLORS.primary, false: colors.text }}
                                        // onCheckColor={COLORS.primary}
                                        // onTintColor={COLORS.primary}
                                        value={toggleCheckBox}
                                        onValueChange={(newValue) => setToggleCheckBox(newValue)}
                                    />
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.text, marginLeft: 8, paddingBottom: 10 }}>
                                    I have read and agree to risk reminder  on p2p trading crypto assets
                                </Text>
                            </View>

                            {toggleCheckBox ?

                                verified ?
                                    <CustomButton
                                        onPress={() => { saveWithRequest() }}
                                        title='Continue'
                                    /> :
                                    <View style={{ alignItems: 'center', marginBottom: 30, marginTop: 10 }}>
                                        <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                            Your P2P request will be manually reviewed If there are any problems with the information you have entered. please wait for phone calls or emails from our staff. If everything is correct it will be verified within 48 hours
                                        </Text>
                                    </View> :
                                ""
                            }
                        </ScrollView>
                    </View>
                </ScrollView>
            </View>
        </>
    )
}


export default P2P;